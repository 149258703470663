import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import image1 from '../components/assets/galleryimg/pic1.jpg';
import image2 from '../components/assets/galleryimg/pic2.jpg';
import image3 from '../components/assets/galleryimg/pic3.jpg';
import image4 from '../components/assets/galleryimg/pic4.jpg';
import image5 from '../components/assets/galleryimg/pic5.jpg';
import image6 from '../components/assets/galleryimg/pic6.jpg';
import image7 from '../components/assets/galleryimg/pic8.jpg';
import image8 from '../components/assets/galleryimg/pic9.jpg';
import image9 from '../components/assets/galleryimg/pic10.jpg';


const PhotoGallery = () => {

  const [index, setIndex] = useState(-1); 


    //const images = [image1, image2, image3];
      const images = [
        {src: image1, alt: "image1"},
        {src: image2, alt: "image2"},
        {src: image3, alt: "image3"},
        {src: image4, alt: "image5"},
        {src: image5, alt: "image6"},
        {src: image6, alt: "image10"},
        {src: image7, alt: "image8"},
        {src: image8, alt: "image9"},
        {src: image9, alt: "image9"},
      ]



  return (
        <div className='gallerycontainer' id='Gallery'>
        <div className='gallerymain'>
            <div className='featureheader'>
                <h1>GALLERY</h1>
                <hr className='horizontalrule'/>
                <h3>The Gallery section showcases the quality and care that 
                  define our services at Valor Drycleaners. From spotless dry 
                  cleaning to pristine home and industrial cleaning results, 
                  this visual collection highlights our expertise and attention 
                  to detail.
                </h3>
            </div>
    </div>

    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems:'center', justifyContent: 'center'}}>
      {images.map((image, idx) => (
        <img
          key={idx}
          src={image.src}
          alt={`Thumbnail ${idx + 1}`}
          style={{ width: '250px', height: '150px', cursor: 'pointer' }}
          onClick={() => setIndex(idx)}
          className='imagegallery'
        />
      ))}
      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        //slides={images.map((src) => ({ src }))}
        slides = {images.map((image) => ({src:image.src, alt:image.alt}))}
        styles={{
          container: { backgroundColor: 'rgba(20, 20, 20, 0.9)' },
          navigation: {
            button: { fontSize: '15rem', color: 'orange' }, // Larger navigation arrows
          },

          captions: {
            caption: {
              fontSize: '1.2rem', // Slightly larger captions
              color: '#fff', // Caption text color
            },
          },
        }}
      />
    </div>
    </div>
  );
};

export default PhotoGallery;
