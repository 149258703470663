import React from 'react'

function Footer() {
  return (
    <div>
        <div className='footercontent'>
            <h2> &copy; 2024 | All rights reserved | Designed by Raymond Iwobi</h2>
        </div>
       
    </div>
  )
}

export default Footer
