import React, {useState} from 'react';
import './style.css'
//import { Link } from 'react-router-dom';
import cleaner from './assets/webimages/women.png';
import {HashLink as Link} from 'react-router-hash-link';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


function Navigation() {
  const [nav, setNav] = useState(false)

  const navswitch = () => {
    setNav(!nav)
  }

  const scrollWithOffset = (el) => {
    const yOffset = -120; // Adjust based on your header height
    const yPosition = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: yPosition, behavior: 'smooth' });
  };
  
  return (
    <>
       < div className='navigationtop' >
      <nav className='navbarmobile'>
      <div>
        <h3 onClick={navswitch}>
          {nav === false ? 
          <div className='navswitchbar'>
            <div className='titlebarmobile'>
            <Link to='/'><h3><img src={cleaner} alt='' width={30} height={30} id='titlename'/>
            Valor Drycleaners</h3></Link>
            </div>
            <MenuIcon id='icon'/>
          </div> 

          : <div><CloseIcon id='icon'/> 
            <div className='titlebarmobile'>
            <Link to='/'><h3><img src={cleaner} alt='' width={30} height={30} id='titlename'/>
            Valor Drycleaners</h3></Link>
            </div>
            <ul className='ulmobilenav'>
            <Link  scroll={scrollWithOffset} to='#featuressection' smooth>Features</Link>
            <Link to='#About' scroll={scrollWithOffset} smooth>About</Link>
            <Link scroll={scrollWithOffset} to='#Services' smooth>Services</Link>
            <Link scroll={scrollWithOffset}  to='#Gallery' smooth>Gallery</Link>
            <Link to='#Testimonial' scroll={scrollWithOffset}  smooth>Testimonial</Link>
            <Link scroll={scrollWithOffset}  to='#Team' smooth>Team</Link>
            <Link to='#Contact' smooth>Contact</Link>
        </ul>

        </div>
        }</h3>
      </div>
      </nav>

     
        <div className='titlebar'>
            <Link to='/'><h3><img src={cleaner} alt='' width={40} height={40} id='titlename'/>
            Valor Drycleaners</h3></Link>
        </div>
        <div className='navigation'>
        <ul>
            <Link  scroll={scrollWithOffset} to='#featuressection' smooth>Features</Link>
            <Link to='#About' scroll={scrollWithOffset} smooth>About</Link>
            <Link scroll={scrollWithOffset} to='#Services' smooth>Services</Link>
            <Link scroll={scrollWithOffset}  to='#Gallery' smooth>Gallery</Link>
            <Link to='#Testimonial' scroll={scrollWithOffset}  smooth>Testimonial</Link>
            <Link scroll={scrollWithOffset}  to='#Team' smooth>Team</Link>
            <Link to='#Contact' smooth>Contact</Link>
        </ul>
        </div>
    </div>
    </>
  )
}

export default Navigation;
